import { Values } from './type-utils';

export const CertificationName = {
  DynatraceAssociateCertification: 'Dynatrace Associate Certification',
  DynatraceProfessionalCertification: 'Dynatrace Professional Certification',
  DynatraceMasterCertification: 'Dynatrace Master Certification',
  ServicesDeliveryObservability: 'Services Delivery Certification - Observability',
  ServicesDeliveryCloudOps: 'Services Delivery Certification - CloudOps',
  ServicesDeliverySaasUpgrade: 'Services Delivery Certification - SaaS Upgrade',
  AceServicesCertification: 'ACE Services Certification',
  AppDeveloperCertification: 'App Developer Certification',
  DynatraceAdvancedSecuritySpecialistCertification: 'Dynatrace Advanced Security Specialist Certification',
  DynatraceDemAndBizAnalyticsSpecialistCertification: 'Dynatrace DEM & Business Analytics Specialist Certification',
  DynatraceAdvancedAutomationSpecialistCertification: 'Dynatrace Advanced Automation Specialist Certification',
  DynatraceAdvancedObservabilitySpecialistCertification: 'Dynatrace Advanced Observability Specialist Certification',
  DynatracePartnerSales: 'Dynatrace Partner Sales',
  DynatracePartnerSalesSpecialist: 'Dynatrace Partner Sales Specialist',
  DynatracePartnerSalesEngineer: 'Dynatrace Partner Sales Engineer',
  ProjectManagement: 'Partner Services Project Management Certification Exam',
} as const;
export type CertificationName = Values<typeof CertificationName>;

export const Perform2025ExamName = {
  Perform2025DynatraceAssociateExam: 'Perform 2025 - Dynatrace Associate Exam',
  Perform2025DynatraceAdvancedSecuritySpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Security Specialist Certification Exam',
  Perform2025DynatraceDemAndBizAnalyticsCertificationExam: 'Perform 2025 - Dynatrace DEM & Business Analytics Certification Exam',
  Perform2025DynatraceAdvancedAutomationSpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Automation Specialist Certification Exam',
  Perform2025DynatraceAdvancedObservabilitySpecialistCertificationExam:
    'Perform 2025 - Dynatrace Advanced Observability Specialist Certification Exam',
} as const;
export type Perform2025ExamName = Values<typeof Perform2025ExamName>;

export const CertificationExamName = {
  DynatraceAssociateExam: 'Dynatrace Associate Exam',
  DynatraceProfessionalWrittenExam: 'Dynatrace Professional Written Exam',
  DynatraceProfessionalPracticalExam: 'Dynatrace Professional Practical Exam',
  ServicesDeliveryObservabilityExam: 'Partner Services Delivery Certification Exam - Observability',
  ServicesDeliveryCloudOpsExam: 'Partner Services Delivery Certification Exam - CloudOps',
  ServicesDeliverySaasUpgradeExam: 'Services Delivery Certification Exam - SaaS Upgrade',
  AppDeveloperExam: 'App Developer Certification Exam',
  DynatraceAdvancedSecuritySpecialistExam: 'Dynatrace Advanced Security Specialist Certification Exam',
  DynatraceDemAndBizAnalyticsSpecialistExam: 'Dynatrace DEM & Business Analytics Certification Exam',
  DynatraceAdvancedAutomationSpecialistExam: 'Dynatrace Advanced Automation Specialist Certification Exam',
  DynatraceAdvancedObservabilitySpecialistExam: 'Dynatrace Advanced Observability Specialist Certification Exam',
  DynatracePartnerSalesEngineerExam: 'Partner Sales Engineer Exam',
  ProjectManagementExam: 'Partner Services Project Management Certification Exam',

  ...Perform2025ExamName,
} as const;
export type CertificationExamName = Values<typeof CertificationExamName>;
