import { ListParamsStrict, ListResourceScope, ParamValueCondition } from './list-params';
import { Asset, ResourceList } from './shared';
import { Values } from './type-utils';

export class UpdateAetherEnvironmentClass {
  startTime?: string | Date;
  endTime?: string | Date;
  timezone?: string;
  eventId?: Asset['id'];
  aetherInitialized?: boolean;
  templateId?: string;
  costCenter?: number;
  environmentId?: string;
  environmentName?: string;
  environmentProvisioningStatus?: AetherProvisioningStatus;
  environmentHealthStatus?: AetherHealthStatus;
  environmentResources?: any; // TODO - further define structure of Resources
  deletedAt?: string | Date | null;
}

export class CreateAetherEnvironmentClass extends UpdateAetherEnvironmentClass {
  startTime: string | Date;
  endTime: string | Date;
  timezone: string;
  eventId: Asset['id'];
  aetherInitialized: boolean;
  templateId: string;
}

export class AetherEnvironmentClass extends CreateAetherEnvironmentClass {
  readonly id: number;
  readonly createdAt: string | Date;
  updatedAt: string | Date;

  event?: Asset;
}

export interface UpdateAetherEnvironment extends UpdateAetherEnvironmentClass {}
export interface CreateAetherEnvironment extends CreateAetherEnvironmentClass {}
export interface AetherEnvironment extends AetherEnvironmentClass {}

export const AetherProvisioningStatus = {
  Deprovisioning: 'DEPROVISIONING',
  Failed: 'FAILED',
  Provisioning: 'PROVISIONING',
  Running: 'RUNNING',
  Scheduled: 'SCHEDULED',
  Stopped: 'STOPPED',
} as const;
export type AetherProvisioningStatus = Values<typeof AetherProvisioningStatus>;

export const AetherHealthStatus = {
  Healthy: 'HEALTHY',
  Pending: 'PENDING',
  Unhealthy: 'UNHEALTHY',
} as const;
export type AetherHealthStatus = Values<typeof AetherHealthStatus>;

export type AetherEnvironmentList = ResourceList<AetherEnvironment>;

export interface InternalAetherEnvironmentListParams extends ListParamsStrict {
  id?: number[];
  startTime?: ParamValueCondition;
  endTime?: ParamValueCondition;
  timezone?: string;
  eventId?: Asset['id'];
  aetherInitialized?: boolean;
  environmentId?: string;
  environmentName?: string;
  provisioningStatus?: AetherProvisioningStatus;
  healthStatus?: AetherHealthStatus;
  scope?: ListResourceScope;
  registrations?: boolean;
}

export const AetherEnvironmentUseCase = {
  Individual: 'individual',
  Group: 'group',
} as const;
export type AetherEnvironmentUseCase = Values<typeof AetherEnvironmentUseCase>;

export type AetherEventConfiguration = Pick<AetherEnvironment, 'startTime' | 'endTime' | 'timezone' | 'templateId'> & {
  environmentUseCase: AetherEnvironmentUseCase;
  costCenter?: number;
};
