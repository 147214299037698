import { ListParams, ListParamsStrict, ListSharedResourceScope, ParamValueCondition } from './list-params';
import { Notification } from './notifications';
import { OrderType } from './order';
import { Registration } from './registration';
import { BasicSurvey } from './surveys';
import { Tenant, TenantConfiguration } from './tenant';
import { Values } from './type-utils';

// Error Response
export interface ErrorResponse {
  status: number;
  message: string;
}

// Activity
export interface Activity {
  progress?: number;
  complete?: boolean;
  completedAt?: string;
  expired?: boolean;
  expiresAt?: string;
  data?: ActivityData;
  assetId?: number;
  asset?: BasicAsset;
  userId?: number;
  user?: User;
  parent?: ActivityParent;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface ActivityParent {
  id: number;
  parent?: ActivityParent;
}

export type BasicActivity = {
  complete: boolean;
  completedAt: string | null;
  expired: boolean;
  expiresAt: string | null;
  progress: number;
  updatedAt: string | null;
};

export interface ActivityData {
  attempts: ActivityAttempt[];
}

export interface ActivityAttempt {
  id: number;
  percentage: number;
  pointsScored: number;
  pointsAvailable: number;
  startTime: number;
  endTime: number;
  passmark: number;
  passed: boolean;
}

// List Activity Params
export interface ActivityListParams extends ListParams {
  assets?: number[] | string;
  activities?: string;
  asset?: boolean | string;
  user?: boolean | string;
  complete?: boolean | string;
  audience?: string;
  region?: string;
  userEmail?: string;
  progress?: ParamValueCondition;
  completedAt?: ParamValueCondition;
  createdAt?: ParamValueCondition;
  updatedAt?: ParamValueCondition;
  lastAccess?: { gte?: string; lte?: string };
  userCeus?: boolean | string;
}

// Activity List
export interface ActivityList {
  readonly count: number;
  readonly rows: ActivityListItem[];
}
export interface ActivityListItem extends Activity {
  asset?: BasicAsset;
  user?: {
    id?: number;
    type?: UserType;
    email?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    title?: string;
    region?: string;
    sfdcId?: string;
    sfdcVertical?: string;
  };
  userCeu?: UserCeu | null;
}

// List SFDC Activity Params
export interface SfdcActivityListParams extends ListParams {
  activities?: string;
  asset?: string;
  complete?: boolean | string;
  data?: boolean | string;
}

// SFDC Activity Report
export interface SfdcActivityReport {
  sfdc: {
    id: string;
    memberCount: number;
  };
  activity: TeamActivityList;
}

// List Team Activity Params
export interface TeamActivityListParams extends ListParams {
  activities?: string;
  asset?: string;
  complete?: boolean | string;
  data?: boolean | string;
}

// Team Activity Report
export interface TeamActivityReport {
  team: BasicTeam;
  activity: TeamActivityList;
}
export interface TeamActivityList {
  readonly count: number;
  readonly rows: TeamActivity[];
}
export interface TeamActivity {
  id?: number;
  type?: string;
  name?: string;
  description?: string;
  tags?: BasicTag[];
  participants?: TeamActivityParticipant[];
}
export interface TeamActivityParticipant extends BasicUser {
  activity: {
    progress: number;
    createdAt: string;
    complete: boolean;
    completedAt: string;
    expired: boolean;
    expiresAt: string;
    updatedAt: string;
    data?: any;
  };
}

// List Activity Params
export interface UserActivityListParams extends ListParams {
  activities?: string;
  asset?: string;
  complete?: boolean | string;
  expired?: boolean | string;
  data?: boolean | string;
  userCeu?: boolean | string;
}

// User Activity Report
export interface UserActivityReport {
  user: BasicUser;
  activity: UserActivityList;
}

export interface GetUserAssetActivityReportParams {
  /** The ID of the asset report activity on */
  assetId: number;
  /** The ID of the user to report activity for */
  userId: number;
}

export interface UserAssetActivityReport {
  activity: BasicActivity;
  children?: UserAssetActivityReport[];
  id: number;
  name: string;
  type: string;
  thumbnail: string;
  user: StrictBasicUser;
  moduleCompletedCount?: number;
  moduleSelectedCount?: number;
}

export interface UserActivityList {
  readonly count: number;
  readonly rows: UserActivity[];
}
export interface UserActivity {
  progress: number;
  complete: boolean;
  completedAt: string;
  createdAt: string;
  expired: boolean;
  expiresAt: string;
  updatedAt: string;
  data?: any;
  asset: UserActivityAsset;
  userCeus?: {
    amount: number;
    createdAt: string;
    testAttempt: {
      id: number;
      complete: boolean | string;
      passed: boolean | string;
      expired: boolean | string;
      startTime: string;
      endTime: string;
      testAssetId: number;
      testAssetName: string;
    };
  };
}

export interface UserActivityAsset {
  id?: number;
  type?: string;
  name?: string;
  thumbnail?: string;
  skillLevel?: string;
  parent?: UserActivityAssetParent | null;
  access?: Access;
  data?: any;
}

export interface UserActivityAssetParent extends BasicAsset {
  parent?: UserActivityAssetParent;
}

// List Asset Activity Params
export interface AssetActivityListParams extends ListParams {
  complete?: boolean | string;
  usertype?: string;
  region?: string;
  views?: boolean | string;
}

// Asset Activity Report
export interface AssetActivityReport {
  asset: ActivityAsset;
  activity: AssetActivityList;
}
export interface ActivityAsset {
  id?: number;
  type?: string;
  name?: string;
  description?: string;
  tags?: BasicTag[];
}
export interface AssetActivityList {
  readonly count: number;
  readonly rows: AssetActivityUserInfo[];
}
interface AssetActivityUserInfo {
  complete: boolean;
  completedAt: string;
  expired: boolean;
  expiresAt: string;
  progress: number;
  updatedAt: string;
  user: AssetActivityUser;
}
interface AssetActivityUser extends BasicUser {
  region: string;
  fullName: string;
  sfdcId: string;
  views?: AssetActivityView[];
}
interface AssetActivityView {
  createdAt: string;
}

// Manager Direct Report Activity Report
export interface ManagerActivityReport {
  manager: ManagerActivityUser;
  activity: ManagerActivityList;
}
export interface ManagerActivityUser extends BasicUser {
  fullName?: string;
}
export interface ManagerActivityList {
  readonly count: number;
  readonly rows: ManagerAssetActivity[];
}
interface ManagerAssetActivity {
  id?: number;
  type?: string;
  name?: string;
  description?: string;
  tags?: BasicTag[];
  participants?: ManagerActivityParticipant[];
}
interface ManagerActivityParticipant extends ManagerActivityUser {
  activity: {
    progress: number;
    complete: boolean;
    completedAt: string;
    expired: boolean;
    expiresAt: string;
    updatedAt: string;
    data?: any;
  };
}

// List Assets Params
export interface AssetListParams extends ListParams {
  id?: number;
  type?: string;
  name?: ParamValueCondition;
  active?: boolean | string;
  skillLevel?: string;
  tags?: string;
  createdAt?: ParamValueCondition;
  updatedAt?: ParamValueCondition;
  startTime?: string;
  endTime?: string;
  scope?: AssetListScope;
  ownerEmail?: string;
}

export interface AssetListParamsStrict extends Omit<ListParamsStrict, 'all'> {
  id?: number;
  type?: AssetType[];
  name?: ParamValueCondition;
  active?: boolean;
  skillLevel?: string;
  tags?: string[];
  createdAt?: ParamValueCondition;
  updatedAt?: ParamValueCondition;
  deletedAt?: ParamValueCondition;
  startTime?: string;
  endTime?: string;
  scope?: AssetListScope;
  ownerEmail?: string;
  audit?: boolean;
}

// Get Asset Params
export interface GetAssetParams {
  all?: boolean | string;
  levels?: number;
  activity?: boolean | string;
  view?: boolean | string;
  visible?: boolean | string;
}

// Asset List
export interface AssetList {
  readonly count: number;
  readonly rows: AssetSummary[];
}

export const AssetListScope = {
  All: ListSharedResourceScope.All,
  Archived: ListSharedResourceScope.Archived,
  Owned: ListSharedResourceScope.Owned,
} as const;
export type AssetListScope = Values<typeof AssetListScope>;

export interface AssetSummary {
  readonly id?: number;
  readonly type?: string;
  readonly name?: string;
  readonly label?: string;
  readonly active?: boolean;
  readonly data?: any;
  readonly ownerId?: number;
  readonly owner?: BasicUser | null;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Asset
export interface Asset {
  readonly id?: number | null;
  type?: string | AssetType | null;
  name?: string | null;
  label?: string | null;
  notes?: string | null;
  version?: string | null;
  description?: string | null;
  prerequisites?: string | null;
  objectives?: string | null;
  language?: string | null;
  skillLevel?: string | AssetSkillLevel | null;
  src?: string | null;
  thumbnail?: string | null;
  coverImage?: any | null;
  duration?: number | null;
  active?: boolean | null;
  restrict?: boolean | null;
  new?: boolean | null;
  updated?: boolean | null;
  data?: string | any | null;
  ownerId?: number | null;
  owner?: BasicUser | null;
  likeCount?: number | null;
  viewCount?: number | null;
  completionPercent?: number;
  required?: boolean;
  moduleCount?: number;
  activity?: AssetActivity;
  participants?: any;
  access?: AssetAccess[];
  requirements?: AssetRequirement[];
  parents?: Asset[];
  children?: Asset[];
  tags?: BasicTag[];
  registrations?: Registration[];
  tests?: BasicTest[];
  assetHierarchy?: AssetHierarchy;
  favorite?: boolean;
  favorites?: Favorite[];
  enrollment?: AssetEnrollment;
  enrollments?: User[];
  announcements?: AssetAnnouncement[];
  instructors?: BasicUser[];
  surveys?: BasicSurvey[];
  exams?: BasicAsset[];
  childLevel1Selections?: AssetSelection[];
  childLevel2Selections?: AssetSelection[];
  childLevel3Selections?: AssetSelection[];
  childLevel4Selections?: AssetSelection[];
  guides?: Asset[];
  ceuData?: CEUData;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface AssetDetails {
  type: AssetType;
  title: string;
  label: string;
  labelPlural: string;
  url: string;
}

export interface AssetSelection {
  readonly id: number;
  parentId: number;
  childLevel1Id: number;
  childLevel2Id: number;
  childLevel3Id: number;
  childLevel4Id: number;
}

// Asset (Basic)
export interface BasicAsset {
  data?: any;
  id?: number;
  language?: string;
  name?: string;
  type?: string;
}

export const AssetSkillLevel = {
  Beginner: 'beginner',
  Intermediate: 'intermediate',
  Advanced: 'advanced',
  Multiple: 'multiple',
  All: 'all',
} as const;
export type AssetSkillLevel = Values<typeof AssetSkillLevel>;

export enum AssetType {
  Category = 'category',
  Certification = 'certification',
  CertificationSeries = 'certification-series',
  Course = 'course',
  Exam = 'exam',
  File = 'file',
  LabGuide = 'lab-guide',
  LabGuideExercise = 'lab-guide-exercise',
  LearningPath = 'learning-path',
  Quiz = 'quiz',
  Section = 'section',
  Series = 'series',
  Session = 'session',
  Training = 'training',
  TrainingSeries = 'training-series',
  Url = 'url',
  Video = 'video',
}

export type AssetOfType<T extends AssetType> = Asset & { type: T };

export function isAssetOfType<T extends AssetType>(asset: Asset, assetType: T): asset is AssetOfType<T> {
  return asset.type === assetType;
}

const ModuleType = {
  [AssetType.Certification]: AssetType.Certification,
  [AssetType.Exam]: AssetType.Exam,
  [AssetType.File]: AssetType.File,
  [AssetType.LabGuide]: AssetType.LabGuide,
  [AssetType.Quiz]: AssetType.Quiz,
  [AssetType.Training]: AssetType.Training,
  [AssetType.Url]: AssetType.Url,
  [AssetType.Video]: AssetType.Video,
} as const;
export type ModuleType = Values<typeof ModuleType>;

export const ModuleTypes: readonly ModuleType[] = Object.values(ModuleType);

export function isModuleType(type: AssetType): type is ModuleType {
  return (ModuleTypes as string[]).includes(type);
}

// Asset Access
export interface AssetAccess {
  readonly id: number;
  readonly name: string;
  readonly roles?: BasicRole[];
}

// Asset Activity
export interface AssetActivity {
  progress?: number;
  complete?: boolean;
  completedAt?: string;
  expired?: boolean;
  expiresAt?: string;
  createdAt?: string;
  updatedAt?: string;
  data?: string | any;
  liked?: boolean;
  parentLevel1Id?: number;
  parentLevel2Id?: number;
  parentLevel3Id?: number;
  parentLevel4Id?: number;
  assetId?: number;
  asset?: Asset;
  userCeu?: UserCeu;
  userId?: number;
}

export interface BasicAssetActivity {
  progress: number;
  complete: boolean;
  completedAt?: string | null;
  updatedAt?: string | null;
  parent?: UserActivityAssetParent;
}

// Asset Announcement List Params
export interface AssetAnnouncementListParams extends ListParams {
  id?: number;
}

// Asset Announcement List
export interface AssetAnnouncementList {
  readonly count: number;
  readonly rows: AssetAnnouncement[];
}

// Asset Announcement
export interface AssetAnnouncement {
  id?: number;
  title?: string;
  description?: string;
  asset?: BasicAsset;
  assetId?: number;
  user?: BasicUser;
  userId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface StrictAssetAnnouncement {
  readonly createdAt: string;
  description: string;
  id: number;
  title: string;
  user?: BasicUser;
}

// Asset Enrollment List Params
export interface AssetEnrollmentListParams extends ListParams {}

// Asset Enrollment List
export interface AssetEnrollmentList {
  readonly count: number;
  readonly rows: AssetEnrollment[];
}

// Asset Enrollment
export interface AssetEnrollment {
  provideEmailNotifications?: boolean;
  provideWebNotifications?: boolean;
  asset?: BasicAsset;
  assetId?: number;
  user?: BasicUser;
  userId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

// Asset Instructor
export interface AssetInstructor {
  id?: number;
  type?: AssetInstructorType;
  asset?: BasicAsset;
  assetId?: number;
  user?: BasicUser;
  userId?: number;
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export const AssetInstructorType = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;
export type AssetInstructorType = Values<typeof AssetInstructorType>;

// Asset Requirement
export interface AssetRequirement {
  assetId: number;
  teamId: number;
  percent: number;
  method: string;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

// Asset Hierarchy
export interface AssetHierarchy {
  childId?: number;
  parentId?: number;
  required?: boolean;
  sort: number;
}

export interface InternalAssetCreateRequestBody {
  access: Access;
  active: boolean;
  completionPercent: number;
  description?: string | null;
  duration: number;
  instructors?: { type: AssetInstructorType; user: ResourceIdObject }[];
  label?: string | null;
  language: string;
  name: string;
  notes: string | null;
  objectives?: string | null;
  prerequisites?: string | null;
  skillLevel: AssetSkillLevel;
  surveys?: ResourceIdObject[];
  tags?: ResourceIdObject[];
  version: string | null;
}

// Asset Test
export interface AssetTest {
  introduction?: string;
  instructions?: string;
  password?: string;
  timeLimit?: number;
  passingPercent?: number;
  provideFeedback?: boolean;
  passedFeedback?: string;
  failedFeedback?: string;
  provideResults?: boolean;
  provideCategoryResults?: boolean;
  questionResults?: QuestionResults;
  provideEmailResults?: boolean;
  provideTranslations?: boolean;
  notifyMailingList?: boolean;
  mailingList?: string[];
}

// Training Asset
export interface TrainingAsset extends Asset {
  data?: {
    public: TrainingDetails;
    private: TrainingDetails;
  };
}

// Training Details
export interface TrainingDetails {
  components: { label: string; url: string }[];
  duration: {
    days: number;
    hours: number;
  };
  prerequisites: { label: string; url: string }[];
  product: {
    price: number;
    points: number;
    stripeId: string;
  };
  registration: {
    waitlist: boolean;
  };
  resources: { label: string; url: string }[];
}

// Training Event Summary
export interface TrainingEventSummary {
  id: number;
  name: string;
  registrations: {
    id: number;
    environment?: {
      id: number;
      status: string;
      components: EnvironmentComponent[];
    };
    user: BasicUser;
  }[];
}

// Session Asset
export interface SessionAsset extends Asset {
  data?: {
    details: {
      startTime: string;
      endTime: string;
      timezone: string;
      timezoneOffset: string;
      virtual: boolean;
      location: string;
      seatLimit: number;
    };
    environment: {
      region?: string;
      template?: string;
      provideDynatraceEnvironment?: boolean;
      provideDynatraceToken?: boolean;
      costCenter?: number;
    };
    instructor: BasicUser;
    meeting: {
      type: string;
      organizerKey?: string;
      trainingKey?: string;
      url?: string;
    };
    reminders: {
      schedule?: {
        hour?: boolean;
        day?: boolean;
        week?: boolean;
      };
      details?: string;
    };
  };
}

export interface ClassmarkerWebhook {
  payload_status: string;
  test: {
    test_name: string;
  };
  result: {
    cm_user_id: string;
    email: string;
    percentage: number;
    points_scored: number;
    points_available: number;
    time_started: number;
    time_finished: number;
    duration: string;
    percentage_passmark: number;
    passed: boolean;
  };
}

// Email
export interface Email {
  recipient: string;
  subject: string;
  bodyText: string;
  bodyHtml: string;
}

// Templated Email
export interface TemplatedEmail<TemplateData extends {} = Record<string, any>> {
  recipient: string;
  sender?: string;
  template: string;
  templateData: TemplateData;
}

export interface BulkTemplatedEmail<TemplateData extends {} = Record<string, any>> {
  sender?: string;
  template: string;
  destinations: {
    recipient: string;
    templateData?: TemplateData;
  }[];
  defaultTemplateData?: TemplateData;
}

// Environment List Params
export interface EnvironmentListParams extends ListParams {
  id?: number;
  type?: string;
  name?: string;
  status?: string;
  startTime?: string;
  endTime?: string;
  eventId?: number;
  eventName?: string;
  userEmail?: string;
  scope?: ListSharedResourceScope;
  tenantId?: number;
  tenant?: boolean;
  provisioningSortOrder?: boolean;
}

// Environment List
export interface EnvironmentList {
  readonly count: number;
  readonly rows: Environment[];
}

// Environment
export interface Environment {
  readonly id?: number;
  type?: string;
  name?: string;
  status?: EnvironmentStatus;
  startTime?: string | Date;
  endTime?: string | Date;
  provider?: EnvironmentProvider;
  region?: string;
  template?: string;
  templateOptions?: EnvironmentTemplateOptions;
  tenantConfig?: TenantConfiguration;
  provisionDynatraceEnvironment?: boolean;
  components?: EnvironmentComponent[];
  costCenter?: number;
  notes?: string;
  creationRetries?: number;
  tenant?: Tenant | null;
  tenantId?: Tenant['id'] | null;
  registrations?: Registration[];
  user?: BasicUser;
  userId?: number;
  owner?: BasicUser;
  ownerId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  deletedAt?: string;
}

export enum EnvironmentStatus {
  Cancelled = 'cancelled',
  Future = 'future',
  Pending = 'pending',
  Running = 'running',
  Starting = 'starting',
  Stopped = 'stopped',
}

export const EnvironmentProvider = {
  Aws: 'aws',
} as const;
export type EnvironmentProvider = Values<typeof EnvironmentProvider>;

// Environment Component
export interface EnvironmentComponent {
  id?: string;
  type?: string;
  name?: string;
  status?: string;
  access?: {
    type?: string;
    publicIp?: string | null;
    username?: string;
    password?: string | null;
    token?: string | null;
    url?: string | null;
    interface?: boolean;
  };
  commands?: EnvironmentCommands[];
}

export interface EnvironmentCommands {
  label: string;
  value: string;
}

// Environment Template Options
export interface EnvironmentTemplateOptions {
  provideDynatraceEnvironmentParam?: boolean;
  provideDynatraceTokenParam?: boolean;
}

// Basic Environment
export interface BasicEnvironment {
  id?: number;
  type?: string;
  name?: string;
  status?: EnvironmentStatus;
  startTime?: string;
  endTime?: string;
}

// Examity User
export interface ExamityUser {
  email: string;
}

// Examity Encrypted User
export interface ExamityEncryptedUser {
  encryption: string;
}

// Examity Enrollment
export interface ExamityEnrollment {
  email: string;
  firstName: string;
  lastName: string;
  courseId: number;
}

// Favorite List Params
export interface FavoriteListParams extends ListParams {
  assetName?: string;
  skillLevel?: string;
  userEmail?: string;
}

// Favorite List
export interface FavoriteList {
  readonly count: number;
  readonly rows: Favorite[];
}

// Favorite
export interface Favorite {
  asset?: Asset;
  assetId?: number;
  course?: Asset;
  courseId?: number;
  user?: User;
  userId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

// GoToTraining Training
export interface GttTraining {
  readonly id: string;
  readonly organizerKey: string;
  readonly trainingKey: string;
  readonly name: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly timezone: string;
  readonly maxSeats: number;
}

// GoToTraining Registration Summary
export interface GttRegistrationSummary {
  total: number;
  registered: boolean;
}

// GoToTraining Registration
export interface GttRegistration {
  organizerKey: string;
  trainingKey: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const DEFAULT_LIST_ORDER_ATTRIBUTES = [
  'amount',
  'billingAddress',
  'createdAt',
  'deletedAt',
  'discount',
  'id',
  'paymentDate',
  'paymentMethod',
  'productName',
  'registrations',
  'status',
  'type',
  'updatedAt',
  'user',
];

// Order List Params
export interface OrderListParams extends ListParams {
  id?: number[];
  type?: OrderType[];
  status?: string;
  productName?: string;
  paymentDateStart?: string;
  paymentDateEnd?: string;
  eventName?: string;
  userEmail?: string;
  eventDateStart?: string;
  eventDateEnd?: string;
}

// Order List
export interface OrderList {
  readonly count: number;
  readonly rows: Order[];
}

// Order
export interface Order {
  readonly id?: number;
  type?: string;
  status?: string;
  productId?: string;
  productName?: string;
  paymentMethod?: string;
  paymentDate?: string;
  amount?: number;
  discount?: number;
  discountCode?: string;
  fee?: number;
  net?: number;
  currency?: string;
  cardholderName?: string;
  cardholderLast4?: string;
  costCenter?: string;
  stripeId?: string;
  stripeCardId?: string;
  stripeFingerprint?: string;
  stripeReceiptURL?: string;
  project?: string;
  notes?: string;
  approver?: BasicUser;
  approverId?: number;
  reviewer?: BasicUser;
  reviewerId?: number;
  registrations?: Registration[];
  user?: BasicUser;
  userId?: number;
  billingAddress?: OrderBillingAddress;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface OrderBillingAddress {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
}

// Basic Order
export interface BasicOrder {
  id?: number;
  type?: string;
  status?: string;
}

// Test List Params
export interface TestListParams extends ListParams {
  id?: number;
  name?: string;
}

// Test List
export interface TestList {
  readonly count: number;
  readonly rows: Test[];
}

// Test
export interface Test {
  readonly id?: number;
  name?: string;
  introduction?: string;
  instructions?: string;
  timeLimit?: number;
  passingPercent?: number;
  provideFeedback?: boolean;
  passedFeedback?: string;
  failedFeedback?: string;
  provideResults?: boolean;
  provideCategoryResults?: boolean;
  questionResults?: QuestionResults;
  provideEmailResults?: boolean;
  provideTranslations?: boolean;
  assetTest?: AssetTest;
  assets?: BasicAsset[];
  questionGroups?: TestQuestionGroup[];
  notifyMailingList?: boolean;
  mailingList?: string[];
  ownerId?: number;
  owner?: BasicUser;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Basic Test
export interface BasicTest {
  id?: number;
  name?: string;
  passingPercent?: number;
  assetTest: AssetTest;
}

export enum QuestionResults {
  None = 'none',
  WithCorrectAnswers = 'with-correct-answers',
  WithoutCorrectAnswers = 'without-correct-answers',
}

// Test Question Group Type options
export enum TestQuestionGroupType {
  all = 'all',
  subset = 'subset',
}

// Test Question Group
export interface TestQuestionGroup {
  readonly id?: number;
  type?: TestQuestionGroupType;
  randomize?: boolean;
  questionLimit?: number;
  questions?: TestQuestion[];
  sort?: number;
  testId?: number;
  test?: BasicTest;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

// Test Question Assignment
export interface TestQuestionAssignment {
  sort: number;
}

// Test Question type values
export enum TestQuestionType {
  multipleChoice = 'multipleChoice',
  checkBox = 'checkBox',
  shortAnswer = 'shortAnswer',
  longAnswer = 'longAnswer',
  fileUpload = 'fileUpload',
  dropdown = 'dropdown',
  message = 'message',
}

// Test Question Grading Method options
export enum TestQuestionGradingMethodType {
  exactMatch = 'exactMatch',
  partialWithDeduction = 'partialWithDeduction',
  partialWithoutDeduction = 'partialWithoutDeduction',
}

// Test Question List Params
export interface TestQuestionListParams extends ListParams {
  id?: number;
  type?: string;
  prompt?: string;
  categoryId?: number;
  categoryName?: string;
}

// Test Question List
export interface TestQuestionList {
  readonly count: number;
  readonly rows: TestQuestion[];
}

// Test Question
export interface TestQuestion {
  readonly id?: number;
  type?: TestQuestionType;
  prompt?: string;
  promptTranslations?: TestPromptTranslations;
  imageSrc?: string;
  categoryId?: number;
  category?: BasicTestCategory;
  pointsAvailable?: number;
  gradingMethod?: TestQuestionGradingMethodType;
  provideFeedback?: boolean;
  correctFeedback?: string;
  incorrectFeedback?: string;
  randomize?: boolean;
  answerLimit?: number;
  answers?: TestAnswer[];
  notes?: string;
  reviewer?: BasicUser;
  reviewerId?: number;
  tests?: BasicTest[];
  questionGroups?: TestQuestionGroup[];
  ownerId?: number;
  owner?: BasicUser;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Test Answer
export interface TestAnswer {
  readonly id?: number;
  prompt?: string;
  promptTranslations?: TestPromptTranslations;
  imageSrc?: string;
  correct?: boolean;
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

// Test Prompt Translations
export interface TestPromptTranslations {
  [key: string]: string;
}

// Test Category List Params
export interface TestCategoryListParams extends ListParams {
  id?: number;
  name?: string;
}

// Test Category List
export interface TestCategoryList {
  readonly count: number;
  readonly rows: TestCategory[];
}

// Test Category
export interface TestCategory {
  readonly id?: number;
  name?: string;
  ownerId?: number;
  owner?: BasicUser;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Basic Test Category
export interface BasicTestCategory {
  id?: number;
  name?: string;
}

// Test Attempt List Params
export interface TestAttemptListParams extends ListParams {
  id?: number;
  testId?: number;
  testName?: string;
  assetId?: number;
  assetName?: string;
  complete?: boolean;
  graded?: boolean;
  passed?: boolean;
  expirationTime?: string;
  userEmail?: string;
  startTime?: string;
  endTime?: string;
  ceuAssetId?: number;
}

// Test Attempt List
export interface TestAttemptList {
  readonly count: number;
  readonly rows: TestAttempt[];
}

// Test Attempt
export interface TestAttempt {
  readonly id?: number;
  complete?: boolean;
  graded?: boolean;
  requiredManualGrading?: boolean;
  passed?: boolean;
  expired?: boolean;
  startTime?: string;
  endTime?: string;
  expirationTime?: string;
  timeSpent?: number;
  passwordRequired?: boolean;
  lastQuestionId?: number;
  furthestQuestionId?: number;
  pointsAvailable?: number;
  percentEarned?: number;
  pointsEarned?: number;
  initialPointsEarned?: number;
  providedFeedback?: string;
  categoryResults?: TestAttemptCategoryResult[];
  adminNotes?: string;
  questionCount?: number;
  questions?: TestAttemptQuestion[];
  userId?: number;
  user?: BasicUser;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  testId?: number;
  testName?: string;
  testIntroduction?: string;
  testInstructions?: string;
  testTimeLimit?: number;
  testPassingPercent?: number;
  testProvideFeedback?: boolean;
  testPassedFeedback?: string;
  testFailedFeedback?: string;
  testProvideResults?: boolean;
  testProvideCategoryResults?: boolean;
  testQuestionResults?: QuestionResults;
  testProvideEmailResults?: boolean;
  testProvideTranslations?: boolean;
  testAssetId?: number;
  testAssetType?: string;
  testAssetName?: string;
  testAssetData?: any;
  testAttemptAutoGrading?: TestAttemptAutoGrading;
  testNotifyMailingList?: boolean;
  testMailingList?: string[];
  ceuAssetId?: number;
}

// Test Attempt Question
export interface TestAttemptQuestion {
  readonly id?: number;
  answered?: boolean;
  flagged?: boolean;
  manuallyGraded?: boolean;
  pointsEarned?: number;
  initialPointsEarned?: number;
  providedFeedback?: string;
  adminNotes?: string;
  answer?: string;
  uploadedFiles?: string[];
  answers?: TestAttemptAnswer[];
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  testQuestionId?: number;
  testQuestionType?: TestQuestionType;
  testQuestionPrompt?: string;
  testQuestionPromptTranslations?: TestPromptTranslations;
  testQuestionImageSrc?: string;
  testQuestionCategoryId?: number;
  testQuestionCategoryName?: string;
  testQuestionPointsAvailable?: number;
  testQuestionGradingMethod?: TestQuestionGradingMethodType;
  testQuestionProvideFeedback?: boolean;
  testQuestionCorrectFeedback?: string;
  testQuestionIncorrectFeedback?: string;
  testQuestionRandomize?: boolean;
  testQuestionAnswerLimit?: number;
}

// Test Attempt Answer
export interface TestAttemptAnswer {
  readonly id?: number;
  eliminated?: boolean;
  selected?: boolean;
  sort?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
  testAnswerId?: number;
  testAnswerPrompt?: string;
  testAnswerPromptTranslations?: TestPromptTranslations;
  testAnswerImageSrc?: string;
  testAnswerCorrect?: boolean;
}

// Test Attempt Auto Grading
export interface TestAttemptAutoGrading {
  readonly testAttemptId?: TestAttempt['id'];
  readonly tenantId?: Tenant['id'];
  testAttempt?: TestAttempt;
  tenant?: Tenant;
  notebookId?: string;
  evaluationId?: string | null;
  extraVars?: any;
  isGraded?: boolean;
  notebookConfig?: any;
  evaluationSummary?: any;
  retries?: number;
}

export interface TestAttemptAutoGradingListParams extends ListParams {
  tenantId?: number;
  testAttemptId?: number;
  notebookId?: string;
  evaluationId?: string;
  isComplete?: boolean;
  isFailed?: boolean;
  isGraded?: boolean;
  hasEvaluation?: boolean;
}

export type TestAttemptAutoGradingList = ResourceList<TestAttemptAutoGrading>;

// Test Attempt Category Result
export interface TestAttemptCategoryResult {
  categoryId?: number;
  categoryName?: string;
  percentEarned?: number;
  pointsEarned?: number;
  pointsAvailable?: number;
}

// Test Preview
export interface TestPreview {
  passwordRequired: boolean;
  questionCount: number;
  testId: number;
  testName: string;
  testIntroduction: string;
  testInstructions: string;
  testTimeLimit: number;
  testPassingPercent: number;
  testAssetId: number;
  testAssetType: string;
  testAssetName: string;
  testStarted: boolean;
}

// Begin Test Params
export interface BeginTestParams {
  password?: string;
  registrationId?: number;
}

// Active Test Attempt
export interface ActiveTestAttempt {
  readonly id?: number;
  startTime?: string;
  expirationTime?: string;
  passwordRequired?: boolean;
  lastQuestionId?: number;
  questionCount?: number;
  questions?: ActiveTestAttemptQuestion[];
  testId?: number;
  testName?: string;
  testIntroduction?: string;
  testInstructions?: string;
  testTimeLimit?: number;
  testPassingPercent?: number;
  testProvideTranslations?: boolean;
  testAssetId?: number;
  testAssetType?: string;
  testAssetName?: string;
  ceuAssetId?: number;
}

// Active Test Attempt Question
export interface ActiveTestAttemptQuestion {
  readonly id?: number;
  answered?: boolean;
  flagged?: boolean;
  answer?: string;
  answers?: ActiveTestAttemptAnswer[];
  uploadedFiles?: string[];
  testQuestionId?: number;
  testQuestionType?: TestQuestionType;
  testQuestionPrompt?: string;
  testQuestionPromptTranslations?: TestPromptTranslations;
  testQuestionImageSrc?: string;
  testQuestionCategoryId?: number;
  testQuestionCategoryName?: string;
  testQuestionAnswerLimit?: number;
}

// Active Test Attempt Answer
export interface ActiveTestAttemptAnswer {
  id?: number;
  eliminated?: boolean;
  selected?: boolean;
  testAnswerPrompt?: string;
  testAnswerPromptTranslations?: TestPromptTranslations;
  testAnswerImageSrc?: string;
}

// Test Report List Params
export interface TestReportListParams extends ListParams {
  id?: number;
  name?: string;
}

// Test Report List
export interface TestReportList {
  count: number;
  rows: TestReportSummary[];
}

// Test Report Summary
export interface TestReportSummary {
  testId?: number;
  testName?: string;
  avgPercentEarned?: number;
  avgPointsEarned?: number;
  pointsAvailable?: number;
  avgTimeSpent?: number;
  attemptCount?: number;
}

// Test Report
export interface TestReport {
  test: TestReportSummary;
  questions: TestReportQuestionList;
}

// Test Report Question List
export interface TestReportQuestionList {
  count: number;
  rows: TestReportQuestion[];
}

// Test Report Question
export interface TestReportQuestion {
  id: number;
  prompt: string;
  correctPercent: number;
  attemptCount: number;
}

// Test Question Report
export interface TestQuestionReport {
  question: TestReportQuestion;
  answers: TestQuestionReportAnswerList;
}

// Test Question Report Answer List
export interface TestQuestionReportAnswerList {
  count: number;
  rows: TestQuestionReportAnswer[];
}

// Test Question Report Answer
export interface TestQuestionReportAnswer {
  id: number;
  prompt: string;
  correct: boolean;
  selectedCount: number;
  selectPercent: number;
}

// Role List Params
export interface RoleListParams extends ListParams {
  id?: number;
  name?: string;
}

// Role List
export interface RoleList {
  readonly count: number;
  readonly rows: Role[];
}

// Role
export interface Role {
  readonly id?: number;
  name?: string;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Role (Basic)
export interface BasicRole {
  readonly id?: number;
  readonly name?: string;
}

// Search Feed
export interface SearchFeedResult {
  id: string;
  fields: SearchFeedField[];
}
export interface SearchFeedField {
  name: string;
  value: string;
}

// SSO Credentials
export interface SsoCredentials {
  username: string;
  password: string;
}

// SSO User
export interface SsoUser {
  readonly isMemberOf: string[];
  readonly givenName: string[];
  readonly sn: string[];
}

// Stripe Charge
export interface StripeCharge {
  amount: number;
  currency: string;
  source: string;
  description: string;
  metadata?: StripeChargeMeta;
  receipt_email?: string;
}
interface StripeChargeMeta {
  email: string;
  name: string;
  promo: string;
}

// Stripe Coupon
export interface StripeCoupon {
  redemptions: number;
}

export enum TagListScope {
  All = 'all',
  Archived = 'archived',
}

// Tag List Params
export interface TagListParams extends ListParams {
  id?: number;
  key?: string;
  name?: string;
}

// Tag List
export interface TagList {
  readonly count: number;
  readonly rows: Tag[];
}

// Tag
export interface Tag {
  readonly id: number;
  key?: string;
  name?: string;
  readonly createdAt: string | Date;
  readonly updatedAt: string | Date;
  readonly deletedAt?: string | Date | null;
}
export class Tag implements Tag {}

export interface UpdateTagDto {
  key?: string;
  name?: string;
  deletedAt?: string | Date | null;
}

export interface CreateTagDto extends UpdateTagDto {}

// Tag (Basic)
export interface BasicTag {
  readonly id?: number;
  readonly name?: string;
}

// Team List Params
export interface TeamListParams extends ListParams {
  id?: number;
  name?: string;
}

// Team List
export interface TeamList {
  count: number;
  readonly rows: Team[];
}

// Team
export interface Team {
  readonly id?: number;
  name?: string;
  mode?: string;
  rule?: TeamRule;
  roles?: BasicRole[];
  owners?: BasicUser[];
  members?: TeamMembership[];
  membership?: {
    role: string;
  };
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// Team Rule
export interface TeamRule extends Array<TeamRuleConditionGroup> {}
export interface TeamRuleConditionGroup extends Array<TeamRuleCondition> {}
export interface TeamRuleCondition {
  attribute: string;
  operator: number;
  value: string;
}

// Team Membership
export interface TeamMembership extends BasicUser {
  memberships?: {
    role: string;
  };
  role?: string;
}

// Team (Basic)
export interface BasicTeam {
  readonly id?: number;
  readonly name?: string;
  readonly memberCount?: number;
}

// Translation
export interface TranslationOptions {
  source: string;
  target: string;
  text: string;
}

export enum UserListScope {
  All = 'all',
  Archived = 'archived',
}

// User List Params
export interface UserListParams extends ListParams {
  id?: number;
  type?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emailExactMatch?: boolean | string;
  company?: string;
  title?: string;
  sfdcId?: string;
  accommodation?: boolean | string;
  updatedAt?: ParamValueCondition;
  deletedAt?: ParamValueCondition;
  roles?: string | string[];
  lastLogin?: ParamValueCondition;
}

// User List
export interface UserList {
  readonly count: number;
  readonly rows: User[];
}
export interface UserSummary {
  readonly id?: number;
  readonly type?: string;
  readonly email?: string;
  readonly fullName?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly company?: string;
  readonly title?: string;
  readonly lastLogin?: string;
  readonly sfdcId?: string;
  readonly accommodation?: boolean;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

// User
export interface User {
  readonly id?: number;
  readonly ssoUid?: string | null;
  type?: UserType;
  username?: string | null;
  email?: string;
  fullName?: string;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  title?: string | null;
  profileImage?: string | null;
  region?: string | null;
  liveChat?: boolean;
  viewingAs?: boolean;
  viewingAsUser?: BasicUser;
  lastLogin?: string;
  sfdcId?: string | null;
  sfdcVertical?: string | null;
  admin?: boolean;
  membership?: boolean;
  accommodation?: boolean;
  data?: string;
  departmentCode?: number;
  managerId?: number;
  manager?: BasicUser;
  directReports?: BasicUser[];
  roles?: BasicRole[];
  hasDtuRole?: boolean;
  ownedTeams?: Team[];
  teams?: Team[];
  nameID?: string;
  sessionIndex?: string;
  access?: number[];
  assetInstructor?: { type: AssetInstructorType };
  assetInstructors?: AssetInstructor[];
  memberships?: { role: string };
  teamMembership?: { role: string };
  assetActivities?: AssetActivity[];
  notifications?: Notification[];
  enrollment?: AssetEnrollment;
  permissions?: UserPermissions;
  tenant?: Tenant;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface UserWithAccess extends User {
  access: NonNullable<User['access']>;
}

export interface BasicUser {
  id?: number;
  type?: UserType;
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  title?: string;
  profileImage?: string;
  region?: string;
  assetInstructor?: AssetInstructor;
}

/**
 * This type is similar to the BasicUser except that the types strictly
 * follow the schema for the "users" table in the database.
 */
export interface StrictBasicUser {
  id: number;
  type: UserType;
  email: string;
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  title: string | null;
  profileImage: string | null;
  region: string;
}

export const UserType = {
  Customer: 'customer',
  Employee: 'employee',
  Partner: 'partner',
  Other: 'other',
} as const;
export type UserType = Values<typeof UserType>;

export interface UserKey {
  readonly id?: number;
  key?: string;
  lastAccess?: string;
  userId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string;
}

export interface UserPermissions {
  accessAdmin?: boolean;
  accessViewer?: boolean;
  contentAdmin?: boolean;
  contentEditor?: boolean;
  contentViewer?: boolean;
  eventAdmin?: boolean;
  eventEditor?: boolean;
  eventViewer?: boolean;
  labAdmin?: boolean;
  labEditor?: boolean;
  labViewer?: boolean;
  financeAdmin?: boolean;
  financeViewer?: boolean;
  reportAdmin?: boolean;
  reportEditor?: boolean;
  reportViewer?: boolean;
  surveyViewer?: boolean;
  surveyEditor?: boolean;
  surveyAdmin?: boolean;
}

export interface UserCeu {
  amount: number;
  userId: number;
  user?: BasicUser;
  assetId: number;
  asset?: BasicAsset;
  testAssetId: number;
  testAsset?: BasicAsset;
  testAttemptId: number;
  testAttempt?: TestAttempt;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly deletedAt: string;
}

export type UserCeuList = ResourceList<UserCeu>;

export interface UserCeuListParams extends ListParams {
  amount?: number;
  userId?: number;
  assetId?: number;
  testAssetId?: number;
  testAttemptId?: number;
}

export interface JobConfig {
  name: string;
  logs: string;
}

export const EnvironmentLevel = {
  Development: 'dev',
  Staging: 'stg',
  Production: 'prod',
} as const;
export type EnvironmentLevel = Values<typeof EnvironmentLevel>;

export interface ExportOptions {
  columns: ExportColumn[];
  startTime?: string;
  endTime?: string;
}

export interface ExportColumn {
  type?: string;
  name: string;
  key: string;
  value: string;
  selected?: boolean;
}

export enum LanguageCode {
  'Chinese' = 'zh',
  'French' = 'fr',
  'German' = 'de',
  'Italian' = 'it',
  'Japanese' = 'ja',
  'Korean' = 'ko',
  'Portuguese' = 'pt',
  'Russian' = 'ru',
  'Spanish' = 'es',
  'Thai' = 'th',
}

export enum SupportedLanguage {
  Chinese = 'chinese',
  English = 'english',
  French = 'french',
  German = 'german',
  Italian = 'italian',
  Japanese = 'japanese',
  Korean = 'korean',
  Portuguese = 'portuguese',
  Russian = 'russian',
  Spanish = 'spanish',
  Thai = 'Thai',
  Multiple = 'multiple',
}

export interface ResourceList<T extends {}> {
  readonly count: number;
  readonly rows: T[];
}

export type Access = { public: true } | { public: false; teams: BasicTeam[] };

export interface ResourceIdObject {
  id: number;
}

export interface AssetAccessCreateUpdate {
  public: false;
  teams: ResourceIdObject[];
}

export interface Reminders {
  dayReminder: boolean;
  hourReminder: boolean;
  weekReminder: boolean;
}

export interface ExamApproval {
  registrationId?: number;
  approved: boolean;
  approvalConsumed: boolean;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export interface CEUData {
  examId: number;
  examName?: string;
  potentialCeus: number;
}

export type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T];
export type OptionalKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? K : never }[keyof T];
